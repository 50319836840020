:root {
	--fontsize:0.8rem;
	font-family:Arial,Helvetica,sans-serif;
	font-size:16px;
	background-color:#ffe0c48a;
}
.hr {
	color:#b0b0b0;
	
}
.alignleft {
	float:left;
	margin:6px;
}
.header-container {
	background:white;
	padding:12px;
	border-radius:10px;

}
.body-container {
	display:flex;
	justify-content:space-between;
}
.header-block {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	margin-top:0px;
	flex-wrap:wrap;
	height:86px;
	background-image:url(../images/sunset.jpg);
	background-size:cover;
	background-position:center;
	border-radius:10px;
	padding:16px;
	}
.content {
	background-image: url(../images/bureau.webp);
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.content-text {
	background-color: hsla(0,0%,100%,.5);
	font-weight: 700;
	border-radius: 5px;
	margin: 12px;
	padding: 12px;
	float: right;
	bottom: auto;
}
.close-box {
	display:flex;justify-content:flex-end;
}
.close-box>div {
	cursor:pointer;
	background-color:hsla(0,0%,100%,.7);
	padding:3px;
	border-radius:3px;
}
.article-title:hover {
	
	background-color:hsla(0,0%,100%,.8);
	cursor:pointer;
}
.photo {
	width:120px;
	height:120px;
	border-radius:50%;
	border:4px solid white;
	float:right;
}
.article-button {
	position: relative;
	top: 95%;
	left: 10px;
}
.article-button>button {
	width: 150px;
	height: 2em;
	line-height: 1.5em;
}

@media screen and (max-width: 3600px) { 
	.header-text {
		font-weight:bolder;
		font-size:3em;
		font-family:serif;
		color:white;
	}
	.content {
		height: 600px;
	}
	#left,#right {
		min-width:200px;
	}
}
@media screen and (max-width: 2600px) { 
	.header-text {
		font-weight:bolder;
		font-size:3em;
		font-family:serif;
		color:white;
	}
	.content-text {
		width: 30%;
	}
	#left,#right {
		min-width:100px;
	}
}
@media screen and (max-width: 1600px) { 
	.header-text {
		font-weight:bolder;
		font-size:3em;
		font-family:serif;
		color:white;
	}
	.content {
		height: 620px;
	}
	.content-text {
		width: 45%;
	}
	#left,#right {
		min-width:50px;
	}
}
@media screen and (max-width: 860px) { 
	.header-text {
		font-weight:bolder;
		font-size:2em;
		font-family:serif;
		color:white;
	}
	.content {
		background-image: none;
		width: 100%;
		height:auto;
	}
	.content-text {
		width: 100%;
		float:none;
	}
	#left,#right {
		min-width:10px;
	}
}
@media screen and (max-width: 600px) { 
	.header-text {
		font-weight:bolder;
		font-size:1.5em;
		font-family:serif;
		color:white;
	}
	.content {
		background-image: none;
		width: 100%;
		height:auto;
	}
}